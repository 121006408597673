<template>
  <WeContainer v-if="ready">
    <div class="row align-items-center">
      <div class="col">
        <h5 class="custom-card-title">
          <i class="fas fa-angle-double-up"></i>
          <span>SİTE ÜST BAŞLIKLARI</span>
        </h5>
      </div>
      <div class="col-auto">
        <span class="btn btn-outline-primary btn-sm" v-on:click="onAddNew"
          ><i class="fas fa-plus"></i> Ekle</span
        >
      </div>
    </div>

    <hr />
    <template v-if="list && list.length">
      <WeCard
        v-for="(item, itemIndex) in list"
        v-bind:key="itemIndex"
        class="mb-3"
        v-bind:class="{
          'border-danger': $v.list.$each[itemIndex].content.$error,
        }"
      >
        <div class="row align-items-center">
          <div class="col-auto text-center" style="width: 50px">
            <h5 class="text-muted mb-0">{{ itemIndex + 1 }}</h5>
          </div>
          <div class="col-12 my-3 col-md my-md-0">
            <input
              type="text"
              class="form-control border-0"
              v-model="item.content"
              maxlength="100"
              placeholder="Başlık"
              v-bind:style="{
                backgroundColor: item.background_color,
                color: item.text_color,
              }"
            />
          </div>
          <div class="col-12 my-3 col-md-12 col-lg-4 my-md-3 my-lg-0">
            <WeInput
              class="mb-0"
              labelClass="d-none"
              v-bind:prepend="siteUrl"
              v-model="item.url"
            />
          </div>
          <div class="col-auto" style="margin-top: -15px">
            <small><i>Yazı</i></small>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="item.text_color"
            ></verte>
          </div>
          <div class="col-auto" style="margin-top: -15px">
            <small><i>Arka Plan</i></small>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="item.background_color"
            ></verte>
          </div>
          <div class="col-auto" style="margin-top: -11px">
            <small class="d-block mb-1"><i>Durum</i></small>
            <WeSwitch v-model="item.is_active" />
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <a
                class="btn btn-sm"
                v-bind:class="{
                  'btn-success': item.start_date && item.end_date,
                  'btn-light': !item.start_date && !item.end_date,
                  'btn-secondary': !item.start_date || !item.end_date,
                }"
                href="#"
                role="button"
                id="dateDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-calendar-alt"></i>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right p-0"
                aria-labelledby="dateDropdown"
                style="min-width: 250px"
              >
                <div class="p-3">
                  <label class="font-14 mb-0">Başlangıç Tarihi</label>
                  <WeDatePicker
                    class="mb-3"
                    v-bind:id="'start-date-' + itemIndex"
                    label="Tarih Seçin"
                    v-bind:format="'DD.MM.YYYY'"
                    v-bind:formatted="'ll'"
                    v-bind:output-format="'YYYY-MM-DD'"
                    v-bind:locale="'tr'"
                    v-bind:no-label="true"
                    v-bind:only-date="true"
                    v-bind:no-button-now="true"
                    v-bind:no-clear-button="false"
                    v-model="item.start_date"
                  />
                  <label class="font-14 mb-0">Bitiş Tarihi</label>
                  <WeDatePicker
                    v-bind:id="'end-date-' + itemIndex"
                    label="Tarih Seçin"
                    v-bind:format="'DD.MM.YYYY'"
                    v-bind:formatted="'ll'"
                    v-bind:output-format="'YYYY-MM-DD'"
                    v-bind:locale="'tr'"
                    v-bind:no-label="true"
                    v-bind:only-date="true"
                    v-bind:no-button-now="true"
                    v-bind:no-clear-button="false"
                    v-model="item.end_date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto pl-md-0">
            <span class="btn btn-danger btn-sm" v-on:click="onRemove(itemIndex)"
              ><i class="fas fa-trash"></i
            ></span>
          </div>
        </div>
      </WeCard>
    </template>
    <template v-else>
      <WeCard class="alert-info mb-3">
        <i class="fas fa-info-circle mr-2"></i> Site üst başlığı ekleyin
      </WeCard>
    </template>
    <div class="d-flex justify-content-center">
      <WeLanguageSwitcher v-model="toptitle.lang" />
      <!-- Submit -->
      <WeSubmitButton
        parent-class="text-center"
        v-bind:submit-status="submit"
        v-on:submit="submitForm"
      />
      <!-- ./Submit -->
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import Verte from "verte";
import "verte/dist/verte.css";

export default {
  name: "List",
  data() {
    return {
      submit: false,
      ready: true,
      default: {
        content: null,
        url: null,
        text_color: "#000",
        background_color: "#ccc",
        start_date: null,
        end_date: null,
        is_active: true,
      },
      list: [],
    };
  },
  validations: {
    list: {
      $each: {
        content: {
          required,
        },
      },
    },
  },
  components: {
    Verte,
  },
  methods: {
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions("toptitle", ["getList", "updateOrCreate"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$error) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      let list = helper.clone(this.list);
      list.map((item) => {
        item.is_active = item.is_active === true ? 1 : 0;
      });

      this.updateOrCreate({
        list: list,
        onSuccess: (result) => {
          this.showAlert(result);
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onAddNew() {
      let defaultItem = helper.clone(this.default);
      this.list.push(defaultItem);
    },
    onRemove(index) {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        text: "Site Üst başlığı silinecek",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.list.splice(index, 1);
        }
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        setTimeout(() => {
          location.reload();
        }, 250);
      });
      this.clearMessage();
    },
    getTopTitles() {
      this.getList({
        onSuccess: (result) => {
          let resultData = result.data ? result.data.items : [];

          if (resultData.length) {
            resultData.map((item) => {
              item.is_active = item.is_active === 1 ? true : 0;

              return item;
            });
          }

          this.list = resultData;
          this.ready = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["shared", "session", "toptitle"]),
    siteUrl() {
      const siteUrl = this.session.config["site.url"];
      return siteUrl ? new URL(this.session.config["site.url"]) : null;
    },
  },
  mounted() {
    this.ready = false;
    this.getTopTitles();
  },
  watch: {
    "toptitle.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getTopTitles();
        }
      },
    },
  },
};
</script>
<style>
.verte__guide {
  border-radius: 50% !important;
  box-shadow: 0px 0px 2px black !important;
}
</style>
